export const primaryMain = 'rgb(43, 87, 205)';
export const primaryLight15 = 'rgba(0, 102, 255, 0.15)';
export const primaryLight10 = 'rgba(0, 102, 255, 0.1)';
export const primaryLight5 = 'rgba(0, 102, 255, 0.05)';
export const primaryLight = 'rgba(82, 52, 212, 1)';
export const primaryHover = 'rgba(40, 79, 183, 1)';
export const primaryActive = 'rgba(34, 67, 155, 1)';
export const secondaryMain = 'rgba(0, 212, 137, 1)';
export const secondaryHover = 'rgba(1, 180, 118, 1)';
export const secondaryActive = 'rgba(0, 143, 94, 1)';
export const primaryMainDark = 'rgba(98, 64, 244, 1)';
export const textParagraph = 'rgba(83, 83, 113, 1)';
export const textHeading = '#121217';

export const white = 'rgba(255, 255, 255, 1)';
export const whiteLight60 = 'rgba(255, 255, 255, 0.6)';
export const whiteLight40 = 'rgba(255, 255, 255, 0.4)';
export const whiteLight20 = 'rgba(255, 255, 255, 0.2)';

export const captionColor = 'rgba(142, 142, 171, 1)';
export const borderColor = '#EBEBF5';
export const inputBorder = 'rgba(215, 215, 236, 1)';
export const backgroundCardExtraLayer = 'rgba(144, 144, 203, 0.1)';
export const iconSecondary = 'rgba(188, 188, 224, 1)';
export const screenBg = '#FAFAFE';
export const states = '#8E8EAB';
export const textCaption = '#8E8EAB';
export const textLightCaption = 'rgba(91, 91, 126, 1)';

export const successMain = 'rgba(9, 186, 122, 1)';
export const successMain20 = 'rgba(15, 193, 129, 0.2)';

export const error = 'rgba(244, 64, 64, 1)';

export const statusWarning = 'rgba(235, 172, 11, 1)';
export const statusWarning20 = 'rgba(255, 194, 38, 0.2)';
export const statusError20 = 'rgba(217, 58, 63, 0.2)';
export const statusSuccess20 = 'rgba(15, 193, 129, 0.2)';
export const statusPrimary10 = 'rgba(100, 64, 255, 0.1)';

export const decorativeBlue = 'rgba(69, 106, 235, 1)';
export const decorativeGreen = 'rgba(10, 209, 139, 1)';
export const decorativeSky = 'rgba(0, 194, 255, 1)';
export const decorativeViolet = 'rgba(166, 96, 255, 1)';
export const decorativePurple = 'rgba(219, 118, 245, 1)';
export const decorativeMint = 'rgba(3, 208, 196, 1)';
export const decorativeOrange = 'rgba(246, 151, 90, 1)';
